import { toast } from "react-toastify";
import dayjs from "dayjs";
import { SystemService } from "@/services/systemService";
import { ApiResponse } from "@/interfaces/Api";
import {
  PasstimeTotalMilesResponse,
  GenericPasstimeResponse,
  GetPasstimeCustomerResponse,
  GetPasstimeGpsHistoryResponse,
  PasstimeCodesPayload,
  PasstimeWarnPayload,
} from "@/interfaces/System";
import {
    PasstimeGetLastLocateRes,
} from "@/features/Accounts/accountsSubviews/AccountDetail/components/GpsView/interfaces";
import { GpsHistoryItemDeprec } from "@/interfaces";
import { DateFormat } from "@/utils/helpers/general";

export function augmentPasstimeCommands(cls: typeof SystemService): void {
    cls.prototype.getPasstimeTotalMiles = async function (inventoryRecId: number) {
    try {
        const { data } = await this.axios.get<ApiResponse<PasstimeTotalMilesResponse>>(
        "/System/GetPasstimeTotalMiles",
        { params: { inventoryRecId } }
        );
        return data.data!;
    } catch (e) {
        console.error(e);
        toast.error("Unable to get Passtime total miles");
        throw e;
    }
    };

    cls.prototype.setPasstimeTotalMiles = async function (inventoryRecId: number, totalMiles: number) {
    try {
        const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        "/System/SetPasstimeTotalMiles",
        { inventoryRecId, totalMiles }
        );
        return data.data!;
    } catch (e) {
        console.error(e);
        toast.error("Unable to set Passtime total miles");
        throw e;
    }
    };

    cls.prototype.addPasstimeCustomer = async function (inventoryRecId: number) {
    try {
        const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        "/System/AddPasstimeCustomer",
        { params: { inventoryRecId } }
        );
        return data.data!;
    } catch (e) {
        console.error(e);
        toast.error("Unable to add Passtime customer");
        throw e;
    }
    };

    cls.prototype.getPasstimeCustomer = async function (inventoryRecId: number) {
        try {
        const { data } = await this.axios.get<ApiResponse<GetPasstimeCustomerResponse>>(
        "/System/GetPasstimeCustomer",
        { params: { inventoryRecId } }
        );
        return data.data!;
    } catch (e) {
        console.error(e);
        toast.error("Unable to get Passtime customer data");
        throw e;
    }
    };

    cls.prototype.updatePasstimeCustomer = async function (inventoryRecId: number) {
    try {
        const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        "/System/UpdatePasstimeCustomer",
        {},
        { params: { inventoryRecId } }
        );
        return data.data!;
    } catch (e) {
        console.error(e);
        toast.error(`Unable to update Passtime customer with invRecId: ${inventoryRecId}`);
        throw e;
    }
    };

    cls.prototype.deletePasstimeCustomer = async function (inventoryRecId: number) {
    try {
        const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        "/System/DeletePasstimeCustomer",
        { params: { inventoryRecId } }
        );
        return data.data!;
    } catch (e) {
        console.error(e);
        toast.error("Unable to delete Passtime customer");
        throw e;
    }
    };

    cls.prototype.updatePasstimeMap = async function (inventoryRecId: number) {
    try {
        const res = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        "/System/UpdatePasstimeMap",
        {},
        { params: { inventoryRecId } }
        );
        if (!res.data.data?.success) {
        console.error(res.data.data);
        throw new Error("Passtime error");
        }
    } catch (e) {
        toast.error("Passtime error");
        console.error(e);
        throw e;
    }
    };

    cls.prototype.getPasstimeLastLocate = async function (inventoryRecId: number) {
    try {
        const res = await this.axios.get<ApiResponse<PasstimeGetLastLocateRes<string>>>(
        "/System/GetPasstimeLastLocate",
        { params: { inventoryRecId } }
        );

        const passTimeResponse = res.data.data;
        if (!passTimeResponse) return undefined;

        const response: GpsHistoryItemDeprec = {
        address: passTimeResponse.address,
        odometer: passTimeResponse.totalMiles,
        lastPing: dayjs(passTimeResponse.eventDate).format(DateFormat.SimpleDateTime),
        course: passTimeResponse.course,
        speed: passTimeResponse.speed,
        latitude: passTimeResponse.lat,
        longitude: passTimeResponse.long,
        };

        return response;
    } catch (e) {
        console.error(e);
        throw e;
    }
    };

    cls.prototype.getPasstimeGpsHistoryByDate = async function (
    inventoryRecId: number,
    beginDate: string,
    endDate: string
    ) {
    try {
        const { data } = await this.axios.get<ApiResponse<GetPasstimeGpsHistoryResponse>>(
        "/System/GetPasstimeGpsHistoryByDate",
        { params: { inventoryRecId, beginDate, endDate } }
        );
        return data.data!;
    } catch (e) {
        console.error(e);
        toast.error("Unable to get Passtime customer GPS history");
        throw e;
    }
    };

    cls.prototype.getPasstimeGpsHistory = async function (inventoryRecId: number) {
    const history = await this.getPasstimeGpsHistoryByDate(
        inventoryRecId,
        "2010-01-01",
        dayjs().format("YYYY-MM-DD")
    );

    if (history.some((h: any) => h.callResult === "false")) return [];

    const standardGpsHistory: GpsHistoryItemDeprec[] = history.map((h: any) => ({
        address: h.address,
        odometer: h.totalMiles,
        lastPing: dayjs(h.utCGpsDate).toString(),
        course: "",
        speed: h.speed,
        latitude: h.latitude,
        longitude: h.longitude,
        locateType: h.locateType,
    }));

    return standardGpsHistory;
    };

    cls.prototype.enableDisablePasstimeDevice = async function (
    inventoryRecId: number,
    enable: boolean
    ) {
    try {
        const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        "/System/EnableDisablePasstimeDevice",
        {},
        { params: { inventoryRecId, enable } }
        );
        if (!data.data?.success) throw new Error();
    } catch (e) {
        console.error(e);
        toast.error(
        `Unable to ${enable ? "enable" : "disable"} passtime device for invRecId: ${inventoryRecId}`
        );
        throw e;
    }
    };

    cls.prototype.sendPasstimeEmergencyCode = async function (inventoryRecId: number) {
    try {
        const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        "/System/SendPasstimeEmergencyCode",
        {},
        { params: { inventoryRecId } }
        );
        if (!data.data?.success) throw new Error();
    } catch (e) {
        console.error(e);
        toast.error(`Unable to send Passtime emergency code for invRecId: ${inventoryRecId}`);
        throw e;
    }
    };

    cls.prototype.sendPasstimeWarningNoDisable = async function (payload: PasstimeWarnPayload) {
    try {
        const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        "/System/SendPasstimeWarningNoDisable",
        payload
        );
        if (!data.data?.success) throw new Error();
    } catch (e) {
        console.error(e);
        toast.error(`Unable to send Passtime warning`);
        throw e;
    }
    };

    cls.prototype.generatePasstimeCode = async function (payload: PasstimeCodesPayload) {
    try {
        const { data } = await this.axios.post<ApiResponse<GenericPasstimeResponse>>(
        "/System/GeneratePasstimeCodes",
        payload
        );
        return data.data!;
    } catch (e) {
        console.error(e);
        toast.error(`Unable to generate passtime codes`);
        throw e;
    }
    };
}