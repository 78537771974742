import { FC, useState, useMemo } from "react";
import { toast } from "react-toastify";
// kendo
import { GridCellProps } from "@progress/kendo-react-all";
import { BasicTable } from "@/components/table/Table";
import { Button } from "@/components/button/Button";
import { Column } from "@/components/table/TableInterface";
import ScOpenCell from "@/components/kendoCells/ScOpenCell";
// state
import { useWholesaleViewCtx } from "../WholesaleViewProvider";
import { useVehicleForm } from "./useVehicleForm";
// interfaces
import { IDefaultFormValues } from "./default";
import {
  TextInput,
} from "@/components";

type VehicleCtx = ReturnType<typeof useVehicleForm>;

const vehicleColumnsInit: Column[] = [
  { field: "stockNum", title: "Stock #" },
  { field: "scOpen", title: "Sc", cell: ScOpenCell },
  { field: "year", title: "Year" },
  { field: "make", title: "Make" },
  { field: "model", title: "Model" },
  { field: "color", title: "Color" },
  { field: "vin", title: "Vin" },
] as const;

const VehicleTable: FC<VehicleCtx> = ({ ...props }) => {
  const availableVehicles = useWholesaleViewCtx((s) => s.formAddlData?.vehicles);
  const setWsFormField = useWholesaleViewCtx((s) => s.setWsFormField);

  // Local state for the search term
  const [searchTerm, setSearchTerm] = useState("");

  /** @deprecated use standard impl */
  const SelectCell: FC<GridCellProps> = ({ dataItem }) => {
    const vehicle: IDefaultFormValues = dataItem;
    return (
      <td>
        <Button
          secondary
          label="Select"
          fillMode="outline"
          loading={props.selectVehicleLoading}
          onClick={async () => {
            props.setSelectVehicleLoading(true);
            try {
              setWsFormField("vehRecId", vehicle.recId || null);
              props.resetVehicleFields(vehicle);
            } catch {
              toast.error("Unable to select vehicle");
            } finally {
              props.setSelectVehicleLoading(false);
            }
          }}
        />
      </td>
    );
  };

  const tableColumns = [
    ...vehicleColumnsInit,
    { field: "selectButton", title: " ", cells: { data: SelectCell } },
  ];

  // Filter the vehicles based on the search term
  const filteredVehicles = useMemo(() => {
    if (!searchTerm) {
      return availableVehicles || [];
    }
    return (availableVehicles || []).filter((vehicle) => {
      const lowerSearch = searchTerm.toLowerCase();
      return (
        (vehicle.stockNum?.toLowerCase().includes(lowerSearch) ||
          vehicle.year?.toString().includes(lowerSearch) ||
          vehicle.make?.toLowerCase().includes(lowerSearch) ||
          vehicle.model?.toLowerCase().includes(lowerSearch) ||
          vehicle.color?.toLowerCase().includes(lowerSearch) ||
          vehicle.vin?.toLowerCase().includes(lowerSearch)) ??
        false
      );
    });
  }, [availableVehicles, searchTerm]);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {/* Simple search input */}
      <div style={{ marginBottom: "1rem" }}>
        {/* <input
          type="text"
          value={searchTerm}
          placeholder="Search vehicles..."
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: "100%", padding: "0.5rem" }}
        /> */}
        <TextInput
            name="search"
            value={searchTerm}
            placeholder="Search vehicles..."
            // className={salesTabStyles.searchBox}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
      </div>

      {/* Kendo Table with Pagination */}
      <BasicTable data={filteredVehicles} columns={tableColumns} pageable sortable />
    </div>
  );
};

export default VehicleTable;
