import { PermissionKey } from "@/env/interfaces";
import dayjs, { Dayjs } from "dayjs";

export interface PasstimeTotalMilesResponse {
  totalMiles: number;
  date: string | null;
  message: string | null;
  callResult: string; // boolean as string
}

export interface GenericPasstimeResponse {
  result: {
    message: string;
    callResult: string; // boolean as string
  };
  success: boolean;
}

export interface GenericSpireonResponse {
  success: boolean;
  message: string;
}

export interface GetPasstimeCustomerResponse {
  accountNumber: string;
  address: string;
  address2: null;
  airtimeExpiration: Date;
  airTimeExpiresTxt: string;
  bankruptcy: boolean;
  callResult: null;
  cellPhone: null;
  city: string;
  cNum: string;
  color: null;
  currentDivision: string;
  daysToExpireValue: string;
  dealerNumber: number;
  deviceStatus: string;
  divisionId: number;
  divisionName: string;
  editCustProductType: string;
  eliteType: string;
  email: null;
  firstName: string;
  firstPayment: Date;
  firstPaymentAmt: number;
  goodUntil: Date;
  graceDays: number;
  hideDevice: boolean;
  iccId: string;
  imei: string;
  imsi: string;
  installDate: Date;
  inTouchHybrid: boolean;
  inventoryStockNumber: string;
  isConsumerRtc: boolean;
  isEditableSiEnabled: boolean;
  lastName: string;
  lastRtcDate: null;
  make: string;
  message: null;
  model: string;
  msrp: number;
  name: string;
  numberOfPayments: null;
  oldSerialNumber: number;
  originalDivision: string;
  paymentAmount: number;
  paymentInfo: null;
  paymentSchedule: string;
  peopleNotes: null;
  phoneNumber: string;
  postalCode: string;
  previousDealerNumber: null;
  productType: string;
  productTypeDisplay: string;
  removeTraxAuto: null;
  renewalDate: Date;
  rtcHowManyTimes: number;
  rtcNumberOfDays: number;
  rtcPer: null;
  rtcState: string;
  rtcUses: number;
  serialNumber: number;
  serviceType: string;
  serviceTypeId: number;
  shipDate: Date;
  showAirTimeTxt: number;
  showDaysToExpire: number;
  showDaysToExpireTxt: string;
  siCapable: boolean;
  siEnabled: boolean;
  startDate: Date;
  state: string;
  status: string;
  sureTracInstall: string;
  terminatedAccount: string;
  totalMiles: number;
  transferFrom: null;
  transferFromDate: null;
  transferTo: null;
  transferToDate: null;
  unit: string;
  vehicleStatus: null;
  vehicleStatusId: null;
  vin: string;
  year: string;
}

export interface PasstimeGpsHistoryItem {
  nId: number;
  created: Date;
  address: string;
  locateType: string;
  latitude: number;
  longitude: number;
  utCGpsDate: Date;
  rawDataId: string;
  status: string;
  speed: string;
  loginId: string;
  totalMiles: number;
  batteryPct: null;
  voltage: number;
  message: null;
  callResult: string;
}

export type GetPasstimeGpsHistoryResponse = PasstimeGpsHistoryItem[];

export interface PasstimeCodesPayload {
  inventoryRecId: number;
  regDays: number;
  warnDays: number;
  codeType: number;
}

export interface PasstimeWarnPayload {
  inventoryRecId: number;
  enableWarn: boolean;
  toneType: number;
  warnDays: number;
}

export class SelectedCompany<TDate extends string | Dayjs = Dayjs> {
  compId: number;
  locId: number;
  orgRecId: number;
  contactName: string | null;
  contactEmail: string | null;
  companyName: string;
  companyLogo: string;
  phone: string;
  phone800: string;
  fax: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  minCreditCardAmount: number;
  code3: string;
  timeZone: string;
  dayLight: boolean;
  nowLocal: TDate;
  nowUtc: TDate;

  constructor(company: SelectedCompany<string | Dayjs>) {
    this.compId = company.compId;
    this.locId = company.locId;
    this.orgRecId = company.orgRecId;
    this.contactName = company.contactName ?? null;
    this.contactEmail = company.contactEmail ?? null;
    this.companyName = company.companyName;
    this.companyLogo = company.companyLogo;
    this.phone = company.phone;
    this.phone800 = company.phone800;
    this.fax = company.fax;
    this.address = company.address;
    this.city = company.city;
    this.state = company.state;
    this.zip = company.zip;
    this.minCreditCardAmount = company.minCreditCardAmount;
    this.code3 = company.code3;
    this.timeZone = company.timeZone;
    this.dayLight = company.dayLight;
    this.nowLocal = dayjs(company.nowLocal) as TDate;
    this.nowUtc = dayjs(company.nowUtc) as TDate;
  }
}

export type EmployeeField = { recId: number; shortName: string; userId: string };
export class UserInfo {
  recId: number;
  userId: string;
  firstName: string;
  lastName: string;

  // Active session info
  selectableCompanies?: SelectableCompany[];
  selectedCompany: SelectedCompany;

  // Auth
  afsLevel: number;
  dclAccess: boolean;
  defaultOrgId: number;
  defaultCompId: number;
  defaultLocId: number;

  // Misc. - this data seems to be in the wrong place
  invChangeStkNum: boolean;
  invPrice: boolean;
  invDelete: boolean;
  backDateInv: boolean;
  backDateExp: boolean;

  permissions?: PermissionKey[];

  constructor(res: UserInfo) {
    this.recId = res.recId;
    this.userId = res.userId;
    this.firstName = res.firstName;
    this.lastName = res.lastName;

    this.selectableCompanies = res.selectableCompanies;
    this.selectedCompany = new SelectedCompany(res.selectedCompany);

    this.afsLevel = res.afsLevel;
    this.dclAccess = res.dclAccess;
    this.defaultOrgId = res.defaultOrgId;
    this.defaultCompId = res.defaultCompId;
    this.defaultLocId = res.defaultLocId;

    this.invChangeStkNum = res.invChangeStkNum;
    this.invPrice = res.invPrice;
    this.invDelete = res.invDelete;
    this.backDateInv = res.backDateInv;
    this.backDateExp = res.backDateExp;

    this.permissions = res.permissions;
  }
}

export interface PhotoInfo {
  orgId: number;
  containerName: string;
  blobName: string;
}

export interface PhotoOutput {
  url: string;
  blobName: string;
}

export interface SelectableCompany {
  companyName: string;
  orgId: number;
  locId: number;
  compId: number;
  state: string;
}

export interface CoRepoType {
  recId: number;
  orgId: number;
  colType: string;
  type: string;
}

export interface FormIds {
  company: {
    bgForm: string | null;
    bgFormSP: string | null;
    bfFormRecid_Unsold: number | null;
    bfFormRecid_Sold: number | null;
    bfFormRecid_SoldSP: number | null;
    bfFormRecid_UnsoldSP: number | null;
    formApp: string | null;
    formEmpVerB: string | null;
    formEmpVerC: string | null;
    formAddVerB: string | null;
    formAddVerC: string | null;
    formTestDrive: string | null;
    formCreditAuth: string | null;
    formAppId: number | null;
    formEmpVerBId: number | null;
    formEmpVerCId: number | null;
    formAddVerBId: number | null;
    formAddVerCId: number | null;
    formTestDriveId: number | null;
    formCreditAuthId: number | null;
    formQuoteFSId: number | null;
    formQuoteLSId: number | null;
    formQuoteQuickFSId: number | null;
    formQuoteQuickLSId: number | null;
    formQuoteCSID: number | null;
    formSNID: number | null;
    formSCInspRecId: number | null;
  };
}
